import React, { useState } from 'react'
import Typography from '../semantic/Typography'
import Button from '../semantic/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaBars } from "react-icons/fa6";
import logo from '../../assets/logo.png';

const Navbar = () => {
    const navigate = useNavigate()
    const [showMobMenu, setShowMenu] = useState<boolean>(false)

    return (
        <>
            <nav className='flex justify-between pt-4 items-center mb-16 fixed top-0 bg-[white] z-[999] right-0 left-0 px-6 lg:px-20'>
                <NavLink to='/home' className='flex gap-3'>
                    <img src={logo} className='w-40 lg:w-52 ml-[-13px] lg:ml-[-20px]' />
                </NavLink>
                <div className='gap-4 items-center hidden lg:flex'>
                    <NavLink to='/' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>Home</Typography>
                    </NavLink>
                    <NavLink to='/about' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/about' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>About</Typography>
                    </NavLink>
                    {/* <NavLink to='/projects' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>Projects</Typography>
                    </NavLink> */}
                    <NavLink to='/services' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/services' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>Services</Typography>
                    </NavLink>
                    <NavLink to='/blogs' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/blogs' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>Blog</Typography>
                    </NavLink>
                    {/* <NavLink to='/contact' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/contact' ? 'text-[#124E66] font-bold' : ''}`}>
                        <Typography>Contact</Typography>
                    </NavLink> */}
                </div>
                <div className='gap-4 items-center hidden lg:flex'>
                    <Button onClick={() => navigate('/contact')} className='bg-Green text-white hidden lg:flex hover:bg-hoverGreen' title='Contact Us' />
                </div>
                {/* <div className='gap-4 items-center hidden lg:flex'>
                    <NavLink to='/login' className='px-3 rounded-full cursor-pointer'>
                        <Typography>Login</Typography>
                    </NavLink>
                    <Button onClick={() => navigate('/register')} className='bg-Green text-white hidden lg:flex hover:bg-hoverGreen' title='Sign Up' />
                </div> */}
                <span className={`block lg:hidden text-xl cursor-pointer ${showMobMenu ? "bg-black text-white  rounded-md p-1" : 'text-black'}`}>
                    <FaBars  onClick={() => setShowMenu(!showMobMenu)} />
                </span>
            </nav>
            <div className={`pt-4 pb-4 flex-col fixed left-0 top-[70px] right-0 bg-[white] z-[999] gap-3 justify-center text-center mb-2 ${showMobMenu ? 'flex' : 'hidden'}`}>
                <NavLink to='/' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/' ? 'text-[#124E66] font-bold' : ''}`}>
                    <Typography>Home</Typography>
                </NavLink>
                <NavLink to='/about' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/about' ? 'text-[#124E66] font-bold' : ''}`}>
                    <Typography>About</Typography>
                </NavLink>
                <NavLink to='/services' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/services' ? 'text-[#124E66] font-bold' : ''}`}>
                    <Typography>Services</Typography>
                </NavLink>
                <NavLink to='/blogs' className={`px-3 rounded-full cursor-pointer ${window.location.pathname === '/blogs' ? 'text-[#124E66] font-bold' : ''}`}>
                    <Typography>Blog</Typography>
                </NavLink>
                <NavLink  to='/contact' className='flex justify-center'>
                    <Button title='Contact Us' />
                </NavLink>

            </div>
        </>
    )
}

export default Navbar