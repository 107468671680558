import React from 'react'
import Container from '../../componenets/common/Container'
import ImgCard from '../../componenets/common/ImgCard'
import Typography from '../../componenets/semantic/Typography'
import Button from '../../componenets/semantic/Button'
import aboutUs from './about-us.png'

const About = () => {
  return (
    <>
    <Container className='flex flex-col lg:flex-row gap-12'>
      <ImgCard img={aboutUs} className='lg:w-[40%] rounded-sm' />
      <div className='flex-1 flex flex-col gap-4 pt-4'>
        <Typography variant='h1' >ABOUT US</Typography>
        <Typography className='text-2xl leading-snug'>A Team of Reliable and Experienced Software Engineers</Typography>
        <Typography className='text-gray-300 text-xs lg:text-sm'>As the CEO of Code Samadhan, I am proud to lead a team of dedicated professionals who consistently deliver exceptional software engineering solutions. Our commitment to quality, innovation, and client satisfaction sets us apart in the industry. Together, we strive to exceed expectations, build lasting partnerships, and shape the future of technology.
          At Code Samadhan, we believe that our strength lies in our diverse expertise and unwavering dedication to our clients. Our team works tirelessly to ensure that every project we undertake is executed with precision and excellence. We embrace the latest technologies and methodologies to create scalable, reliable, and innovative solutions tailored to meet the unique needs of each client. Our success is built on the foundation of strong collaboration and open communication. We value the contributions of every team member and encourage a culture of continuous learning and improvement. By fostering an environment where creativity and technical expertise thrive, we are able to deliver cutting-edge solutions that drive business success.</Typography>
        <Typography className='font-extrabold'>Vishawjeet Singh</Typography>
      </div>
    </Container>
      {/* <Container className='mt-10 bg-white'>
      <div className='flex flex-col lg:flex-row justify-between items-center mb-20'>
        <div className='flex-1'>
          <Typography variant='h3' className='text-black'>OUR TEAM</Typography>
          <Typography className='text-2xl text-black'>Meet Our Leaders</Typography>
        </div>
      </div>
    <div className='flex flex-wrap lg:flex-nowrap gap-5 '>
      <ImgCard img='https://i.pinimg.com/originals/40/e9/bf/40e9bf2d74086fe18d9d172bff7e47ba.jpg' className='flex-1 w-[33%]'/>
      <ImgCard img='https://th.bing.com/th/id/OIP.S-EjfLxOv8nRWdKCQdtTjwHaHa?w=512&h=512&rs=1&pid=ImgDetMain' className='flex-1 w-[33%]'/>
      <ImgCard img='https://th.bing.com/th/id/OIP.-RJJqWFdXa2qG-F9QU19YQHaFj?w=1920&h=1440&rs=1&pid=ImgDetMain' className='flex-1 w-[33%]'/>
    </div>
      </Container> */}
    </>
  )
}

export default About