import React, { useEffect, useState } from 'react'
import Typography from '../../componenets/semantic/Typography'
import CustomInput from '../../componenets/semantic/Input'
import CustomTextArea from '../../componenets/semantic/TextArea'
import Button from '../../componenets/semantic/Button'
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
import { GiWaterDrop } from "react-icons/gi";
import { contactInfo } from '../../config/config'
import { useForm } from '@formspree/react';
import Modal from '../../componenets/common/Modal'
import ThankYouModal from './ThankYouModal'

const Contact = ({ isHomePage = false }: any) => {
  const [state, handleSubmit, ResetFunction] = useForm("mayrgjvg");

  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between items-start gap-8'>
        <Modal isOpen={state.succeeded} onClose={() => ResetFunction()}>
          <div className='flex justify-center items-center'>
            <ThankYouModal />
          </div>
        </Modal>
        <div className='flex-1'>
          <Typography className='text-xl font-bold'>  CONTACT US</Typography>
          <Typography className='text-3xl lg:text-3xl py-3 lg:py-7'>Get in touch with our team today</Typography>
          <Typography className='text-xs lg:text-md'>We are here to listen and collaborate—contact Code Samadhan today to discuss your project and turn your software vision into reality.</Typography>
          <Typography className='flex items-center gap-3 mt-16 '><FaPhone />{contactInfo.phone}</Typography>
          <Typography className='flex items-center gap-3 py-4'><MdOutlineMail />{contactInfo.email}</Typography>
          <Typography className='flex items-center gap-3'><GiWaterDrop className='rotate-180' />{contactInfo.address}</Typography>
        </div>
        <form onSubmit={handleSubmit} className='flex-1 flex flex-col w-[100%] lg:w-[50%] gap-3'>
          <CustomInput id='name' name='name' placeholder='Name' />
          <CustomInput id='email' name='email' placeholder='Email' />
          <CustomInput id='company_email' name='company_email' placeholder='Company Name' />
          <CustomTextArea id='message' name='message' placeholder='Message' className='h-52' />
          <Button type='submit' className={`w-full rounded-xl ${isHomePage ? 'bg-white !text-black' : ''}`} title='Submit Message' />
        </form>
      </div>
    </>
  )
}

export default Contact