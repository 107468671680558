import Homepage from "../../pages/Homepage/Homepage";
import Contact from "../../pages/Contact/Contact";
import Team from "../../pages/Team/Team";
// import Services from '../../pages/services/Services'
import Blog from '../../pages/Blog/Blog'
import About from '../../pages/About/About'
import withLayout from "./WithLayout";
// import SingleService from "../../pages/services/SingleService";
import SingleProject from "../../pages/Projects/SingleProject";
import Projects from "../../pages/Projects/Projects";
import SingleBlog from "../../pages/Blog/SingleBlog";

const AllComponents: Record<string, ({}: any) => JSX.Element> = {
    Homepage,
    Contact,
    Team,
    // Services,
    Blog,
    About,
    // SingleService,s 
    SingleProject,
    Projects,
    SingleBlog
};

const wrapComponentsWithLayout = () => {
    const wrappedComponents: Record<string, React.ComponentType<any>> = {};
    for (const key in AllComponents) {
        if (Object.prototype.hasOwnProperty.call(AllComponents, key)) {
            wrappedComponents[key] = withLayout(AllComponents[key]);
        }
    }
    return wrappedComponents;
};

export default wrapComponentsWithLayout();
